import { Component, createRef } from 'react';
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { pmReport, locationlist } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';
import * as momentTz from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import './Admin.scss';


export default class MembershipReport extends Component {
    initialSearchState;
    search;
    startDatePicker;
    endDatePicker;
    config;
    id;
    filterSearch;
    constructor(props) {
        super(props);

        this.dataTable = createRef();

        this.initialSearchState = {
            startdate:{ field: 'startdate', value: '', op: '>=', join: 'AND'},
            enddate: { field: 'enddate', value: '', op: '<=', join: 'AND' },
            email: { field: 'email', value: '',  op: 'LIKE', join: 'AND' },
            autorenewalStatus: { field: 'autorenewal', value: '',  op: '=', join: 'AND' },

        }
        this.state = {
            search: this.initialSearchState,
            searchData: false,
            modal: false,
            tableData: [],
            salesDetails: {},
            category: [],
            sales: [],
            issearch: false,
            saleid: { value: '', valid: false, error: '' },
            locations: [],
            isdisabled: false,
            location: '',
            locationlength:0,
            isActiveCheckbox : "",
            isDateFromCheckBox: "",
            DateCheckBoXValue: '',
            currentDate : '',
            SearchButton : false,
            locationAllSelected: false
        }

        this.config = {
            name: 'membershipReport',
            url: pmReport,
            isReport: true,
            params: {},
            columns: [
                { name: 'Member First Name', property: 'firstname', width: '150px' },
                { name: 'Member Last Name', property: 'lastname', width: '150px' },
                { name: 'Member Email', property: 'email', width: '150px' },
                { name: 'Membership Plan Name', property: 'planname', width: '200px', sort: true },
                { name: 'Member Subscription Date',property: 'startdate', sort: true, width: '200px',columnType: 'custom', customColumn: this.customStartDate },
                { name: 'Member Subscription End Date', property: 'enddate', sort: true, width: '170px', columnType: 'custom', customColumn: this.customEndDate },
                { name: 'Member Auto Renewal Status', property: 'autorenewal', sort: true, width: '170px', columnType: 'custom', customColumn: this.customeAutoRenew },
                {
                    name: '', property: 'id', columnType: 'actions', actions: [], customHeader: "CSV", width: '170px',
                }
            ],
            defaultFilter: [
                {
                    "field": "status",
                    "value": "Active",
                    "op": "=",
                    "join": "AND"
                }
            ],
            sort: [{"field": "startdate", "direction": "desc" }],
            field: ['email', 'planname', 'startdate', 'status', 'enddate', 'autorenewal', 'membershipplanid', 'buyerid','firstname','lastname','invoicenumber',"purchaseamount","couponcode","discountamount","creditspurchased","creditsused","creditavailable"],
       
            csvColumn: [
                { name: 'Coupon Code Applied', property: 'couponcode'},
                { name: 'Coupon Value Applied', property: 'discountamount'},
                { name: 'Member Paid Amount', property: 'purchaseamount'},
                { name: 'Member Invoice Number', property: 'invoicenumber'},          
                { name: 'Member DG Credits Awarded', property: 'creditspurchased'},                
                { name: 'Member DG Credits Available', property: 'creditavailable'},                
                { name: 'Member DG Credits Used', property: 'creditsused'},
            ],
            filename: "membership-report",
            pagination: true,
            ignoreRowBackground: true
          
        };

        this.search = {};
        this.startDatePicker = false;
        this.endDatePicker = false;
        this.id = null;
        this.locations = [];

    }
    

    componentDidMount = () => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBack);
    }

    onBack = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }
    customEndDate = (value) => {
        if (value['enddate']) {
            return `${moment.utc(value['enddate']).format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };
    customStartDate = (value) => {
        if (value['startdate']) {
            return `${moment.utc(value['startdate']).format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';

    }
    customeAutoRenew = (value) => {
        if(value['autorenewal'] == 1 ){
            return 'Enabled'

        }
        else if(value['autorenewal'] == 0) {
            return 'Disabled'
        }
        else{
            return '- - -'
        }
    }
    customeCouponCode = (value) => {
        if(value['couponcode']){
            return 'Yes'
        }else{
            return 'NO'
        }
    }

    searchInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
        }, () => {
            if (event.target.value === '')
                this.checkSearch();
        });
    }

    resetState = () => {
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            searchData: false,
            issearch: false,
            isdisabled: false,
            isActiveCheckbox: '',
            isDateFromCheckBox: false,
            DateCheckBoXValue: '',
            currentDate: '',
            locationAllSelected: false,
            SearchButton: false
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
        }
    }

    enableSearch = () => {
        var search = {
            startdate: { ...this.state.search.startdate, value: this.state.isDateFromCheckBox ? this.state.DateCheckBoXValue : this.state.search.startdate.value ? moment.tz(moment(this.state.search.startdate.value).format('MM/DD/YYYY hh:mm:ss'), "US/Eastern").startOf("day").utc().format("YYYY-MM-DD HH:mm:ss") : '' },
            enddate: { ...this.state.search.enddate, value: this.state.isDateFromCheckBox ? this.state.currentDate : this.state.search.enddate.value ? moment(new Date(this.state.search.enddate.value)).tz("US/Eastern").endOf("day").utc().format("YYYY-MM-DD HH:mm:ss") : '' },
            email: { ...this.state.search.email, value: this.state.search.email.value ?  this.state.search.email.value  : ''   },
            autorenewalStatus: { ...this.state.search.autorenewalStatus },

        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    checkSearch = () => {
        let isenable = false;
        let issearchData = this.state.searchData;
        for (let control of Object.keys(this.state.search)) {
            if (this.state.search[control].value) {
                isenable = true;
                break;
            }
        }
        this.setState({
            ...this.state,
            search: isenable ? this.state.search : this.initialSearchState,
            searchData: isenable ? this.state.searchData : false,
            issearch: isenable
        }, () => {
            if (!isenable && issearchData)
                this.dataTable.current.searchData();
        });
    }


    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }

    setDate = (control, date) => {
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [control]: {
                    ...this.state.search[control],
                    value: date
                },
            }
        },() => {
            this.setState({
                ...this.state,
                isActiveCheckbox: '',
                isDateFromCheckBox: false,
                DateCheckBoXValue: '',
                currentDate: ""
            },()=>{
            })
        });

        }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }



    render() {
        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }} id="sale-hostory-report">
                <div className="sale-search">
                    <div className="sales-list-search">
                        <Form className="sales-list-font">
                            <div className="sales-search-container">
                                <Row>
                                <Col>
                                    <label>Bidder Email</label>
                                    <Form.Control onKeyDown={this._handleKeyDown} name="email" value={this.state.search.email.value} onChange={(event) => { this.searchInputChange(event) }}>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <label>Subscription Start Date</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.start.state.open && e.preventDefault()}>
                                                <DatePicker ref={r => this.start = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.startdate.value} selected={this.state.search.startdate.value} onChange={date => { this.setDate('startdate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    
                                    <Col>
                                        <label>Subscription End Date </label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.end.state.open && e.preventDefault()}>
                                                <DatePicker minDate={this.state.search.startdate.value ?  new Date(new Date(this.state.search.startdate.value).setDate(new Date(this.state.search.startdate.value).getDate() + 1)) : ''} ref={r => this.end = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.enddate.value} selected={this.state.search.enddate.value} onChange={date => { this.setDate('enddate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col> 
                                    <Col className={"px-1"}>
                                        
                                        <label>Auto Renewal</label>
                                        <Form.Control as="select" name="autorenewalStatus" value={this.state.search.autorenewalStatus.value} onChange={(event) => { this.searchInputChange(event) }}>
                                           <option value='' selected>All</option>
                                           <option value='1'>Enabled</option>
                                           <option value='0'>Disabled</option>
                                       </Form.Control> 
                                   </Col>   
                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="seller-search"  onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>                      
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="sale-list">
                    <Datatable config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData}></Datatable>
                </div>
            </div>
        );
    }
}